import { Path as NavigatePath, NavigateOptions } from 'react-router-dom';
import { CurrentPath } from '../../shared/typings';
import {
  ShareCommerce,
  ShareBroadcastCommerce,
} from '../../modules/commerce/v2/typings';
import { CustomFieldsTypes } from '../../modules/custom-fields/v1/typings';
import { OpenCustomerChatData } from '../../modules/customers/v1/typings';
import { AnalyticsEventNames, AnalyticsEventOptions } from '../../shared/typings/analytics';
import { WabaIntegrationMemberStatus } from '../../modules/waba-channels/v1/typings';
import { FrequencyCappingState } from '../../modules/channel-management/v1/typings';

export default abstract class BasePlatform {
  abstract isCurrentPlatform(): boolean;
  abstract getToken(): Promise<string>;
  abstract goBack(): void;
  abstract getUtmSource(): string;
  abstract openRenewalBlocker(): void;
  abstract finishActivity(): void;
  abstract openIntegrations(): void;
  abstract uploadCustomersFromPhonebook(): void;
  abstract finishActivityWithError(message: string): void;
  abstract logout(): void;
  abstract openPricingPlans(): void;
  abstract openNavigationDrawer(): void;
  abstract setStatusBarColor(color?: string): void;
  abstract setNavigationBarColor(color?: string): void;
  abstract getVersion(): number;
  abstract addGroupMembers(
    groupId: string,
    groupChatId: string,
    groupChatName: string
  ): void;
  abstract messageGroupMember({
    customerId,
    customerName,
    chatId,
    phoneNumber,
    integrationId,
    integrationWabaNumber,
  }: {
    customerId: string;
    customerName: string;
    chatId?: string;
    phoneNumber?: string;
    integrationId?: string;
    integrationWabaNumber?: string;
  }): void;
  abstract openGroupChat(
    groupChatId: string,
    groupId: string,
    groupChatName: string,
    groupAccessLevel: string,
    canManageGroupAccess: boolean
  ): void;
  abstract shareCommerce(data: ShareCommerce): void;
  abstract inviteContact(): void;
  abstract hasMethod(functionName: string): boolean;
  abstract selectTemplateFromList(): void;
  abstract shareBroadcastCommerce(data: ShareBroadcastCommerce): void;
  abstract openCustomerDetailActivity(
    customerName: string,
    customerPhoneNumber: string
  ): void;
  abstract openDeepLink(url: string): void;
  abstract openLink(url: string): void;
  abstract trackEvent(
    eventName: AnalyticsEventNames,
    properties?: AnalyticsEventOptions
  ): void;
  abstract openCustomerChat(data: OpenCustomerChatData): void;
  abstract sendDataDebug(data: any): void;
  abstract copyToClipboard(data: string): Promise<any>;
  abstract redirect(url: string): void;
  abstract setRoutePath(
    path: Partial<NavigatePath>,
    options?: NavigateOptions
  ): void;
  abstract shareData(data: string): void;
  abstract finishActivityWithResultOk(): void;
  abstract deleteCustomField(
    customFieldId: string,
    type?: CustomFieldsTypes
  ): void;
  abstract createCustomField(
    customFieldId: string,
    type: CustomFieldsTypes
  ): void;
  abstract updateCustomField(
    customFieldId: string,
    type: CustomFieldsTypes
  ): void;
  abstract retargetTemplate(data: {
    action: string;
    chatId: string | null;
    groupId: string;
    broadcastId: string;
    count: number;
  }): void;
  abstract getTemplateData(id: string): void;
  abstract sendCurlForTemplateAsApi(curl: string): void;
  abstract onAddToBroadcastList(): void;
  abstract getSelectedCustomerIds(): void;
  abstract changeCustomToken(token: string, iframeId: string): void;
  abstract getCurrentPath(iframeId: string): Promise<CurrentPath>;
  abstract dialog360Connect(): Promise<{
    client: string;
    channels: string[];
    revokedChannels?: string[];
  }>;
  abstract openBotBuilderModal(botId: string): void;
  abstract closeBotBuilderModal(): void;
  abstract openImportExcelModal(): void;
  abstract openAutoRetrySettingsModal(broadcastId: string): void;
  abstract setAutoRetry(broadcastId: string,state: FrequencyCappingState): void;
  abstract setUserStatus(status: WabaIntegrationMemberStatus | null, statusExpiration: string | null): void;
  abstract sendChannelMembersInfo(
    groupId: string,
    memberCount: number,
    showMemberCount: boolean
  ): void;
  abstract switchOrganisation(orgId: string): void;
}
