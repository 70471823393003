import { Path as NavigatePath, NavigateOptions } from 'react-router-dom';
import { ShareBroadcastCommerce } from '../../modules/commerce/v2/typings';
import { ShareCommerce } from '../../modules/commerce/v2/typings';
import { TeamMemberContact } from '../../modules/team-management/v1/typings';
import { CurrentPath, TemplateMessageData } from '../../shared/typings';
import BasePlatform from './base';
import { AnalyticsEventNames, AnalyticsEventOptions } from '../../shared/typings/analytics';
import { OpenCustomerChatData } from '../../modules/customers/v1/typings';
import { CustomFieldsTypes } from '../../modules/custom-fields/v1/typings';
import { Template } from '../../shared/typings/template';
import { WabaIntegrationMemberStatus } from '../../modules/waba-channels/v1/typings';
import { FrequencyCappingState } from '../../modules/channel-management/v1/typings';

enum AndroidMessageTypes {
  LOGIN = 'LOGIN',
  RELOAD_PAGE_DATA = 'RELOAD_PAGE_DATA',
  TEAM_CONTACT_ADDED = 'TEAM_CONTACT_ADDED',
  TEMPLATE_DATA_SEND = 'TEMPLATE_DATA_SEND',
  REFRESH_CUSTOMERS = 'REFRESH_CUSTOMERS',
  EVENT_SUCCESS = 'EVENT_SUCCESS',
  PREFILL_TEMPLATE = 'PREFILL_TEMPLATE',
  SET_USER_STATUS = 'SET_USER_STATUS',
}

export enum RefreshTypes {
  'GROUP_MEMBERS' = 'GROUP_MEMBERS',
  'GROUPS' = 'GROUPS',
  'BOTS' = 'BOTS',
  'CUSTOMERS' = 'CUSTOMERS',
}

declare global {
  interface Window {
    Android: {
      copyToClipboard: (data: string) => void;
      requestSignInToken: () => void;
      selectTemplateFromList: () => void;
      goBack: () => void;
      openRenewalBlocker: () => void;
      logout: () => void;
      openPricingPlans: () => void;
      uploadCustomersFromPhonebook: () => void;
      finishActivity: () => void;
      openIntegrations: () => void;
      finishActivityWithError: (message: string) => void;
      openNavigationDrawer: () => void;
      setStatusBarColor: (color: string) => void;
      setNavigationBarColor: (color: string) => void;
      getVersion: () => number;
      shareData: (data: string) => void;
      addGroupMembers: (
        groupId: string,
        groupChatId: string,
        groupChatName: string
      ) => void;
      messageGroupMember: (
        customerId: string,
        customerName: string,
        chatId?: string,
        phoneNumber?: string,
        integrationId?: string | null
      ) => void;
      openGroupChat: (
        groupChatId: string,
        groupId: string,
        groupChatName: string,
        groupAccessLevel?: string,
        canManageGroupAccess?: boolean
      ) => void;
      shareCommerce: (data: string) => void;
      shareBroadcastCommerce: (data: string) => void;
      inviteContact: () => void;
      openCustomerDetailActivity: (
        customerName: string,
        customerPhoneNumber: string
      ) => void;
      openDeepLink: (url: string) => void;
      openLink: (url: string) => void;
      trackEvent: (eventName: AnalyticsEventNames, properties?: AnalyticsEventOptions) => void;
      finishActivityWithResultOk: () => void;
      retargetTemplate: (data: string) => void;
      getTemplateData: (id: string) => void;
      sendCurlForTemplateAsApi: (curl: string) => void;
      changeCustomToken: (token: string) => void;
      openBotBuilderModal: (botId: string) => void;
      closeBotBuilderModal: () => void;
      openImportExcelModal: () => void;
      openAutoRetrySettingsModal: (broadcastId: string) => void;
      setUserStatus: (status: WabaIntegrationMemberStatus | null, statusExpiration: string | null) => void;
      setAutoRetry: (broadcastId: string,state: FrequencyCappingState) => void;
      switchOrganisation: (orgId: string) => void;
    };
    messageFromAndroid: (receivedMessage?: unknown) => void;
  }
}

type AndroidLoginRequestCache = {
  login?: {
    cachedPromiseResolve: (value: string | PromiseLike<string>) => void;
  };
};

type AndroidRequestCache = AndroidLoginRequestCache;

type AndroidLoginResult = {
  messageType: AndroidMessageTypes.LOGIN;
  messageData: {
    token: string;
  };
};

type AndroidReloadPageDataResult = {
  messageType: AndroidMessageTypes.RELOAD_PAGE_DATA;
  messageData: {
    refreshType: RefreshTypes;
  };
};

type AndroidTeamContactAdded = {
  messageType: AndroidMessageTypes.TEAM_CONTACT_ADDED;
  messageData: TeamMemberContact;
};

type AndroidTemplateDataSend = {
  messageType: AndroidMessageTypes.TEMPLATE_DATA_SEND;
  messageData: TemplateMessageData;
};

type AndroidCustomerDataChange = {
  messageType: AndroidMessageTypes.REFRESH_CUSTOMERS;
  messageData: {
    customerName: string;
    customerId: string;
  };
};

type AndroidEventSuccess = {
  messageType: AndroidMessageTypes.EVENT_SUCCESS;
  messageData: {
    successMessage: string;
  };
};

type AndroidPrefillTemplate = {
  messageType: AndroidMessageTypes.PREFILL_TEMPLATE;
  messageData: Template;
};

type AndroidSetUserStatus = {
  messageType: AndroidMessageTypes.SET_USER_STATUS;
  messageData: {
    status: WabaIntegrationMemberStatus | null;
    statusExpiration: string | null;
  };
};

type AndroidResult =
  | AndroidLoginResult
  | AndroidReloadPageDataResult
  | AndroidTeamContactAdded
  | AndroidTemplateDataSend
  | AndroidEventSuccess
  | AndroidCustomerDataChange
  | AndroidPrefillTemplate
  | AndroidSetUserStatus;

export default class Android extends BasePlatform {
  cachedPromise: AndroidRequestCache;

  constructor() {
    super();
    this.cachedPromise = {};
  }

  private listenForMessageFromAndroid() {
    window.messageFromAndroid = (receivedMessage) => {
      if (typeof receivedMessage !== 'string') {
        return;
      }

      this.handleMessageFromAndroid(JSON.parse(receivedMessage));
    };
  }

  private handleMessageFromAndroid({
    messageType,
    messageData,
  }: AndroidResult) {
    switch (messageType) {
      case AndroidMessageTypes.LOGIN:
        this.cachedPromise.login?.cachedPromiseResolve(messageData.token);
        delete this.cachedPromise.login;
        break;
      case AndroidMessageTypes.RELOAD_PAGE_DATA: {
        const customEvent = new CustomEvent('reloadpagedata', {
          detail: { refreshType: messageData.refreshType },
        });
        document.dispatchEvent(customEvent);
        break;
      }
      case AndroidMessageTypes.TEAM_CONTACT_ADDED: {
        const customEvent = new CustomEvent('teamContactAdded', {
          detail: messageData,
        });
        document.dispatchEvent(customEvent);
        break;
      }
      case AndroidMessageTypes.TEMPLATE_DATA_SEND: {
        const customEvent = new CustomEvent('templateDataSend', {
          detail: messageData.messageData,
        });
        document.dispatchEvent(customEvent);
        break;
      }
      case AndroidMessageTypes.REFRESH_CUSTOMERS: {
        const customEvent = new CustomEvent('refreshCustomers', {
          detail: messageData,
        });
        document.dispatchEvent(customEvent);
        break;
      }
      case AndroidMessageTypes.EVENT_SUCCESS: {
        const customEvent = new CustomEvent('successEvent', {
          detail: messageData,
        });
        document.dispatchEvent(customEvent);
        break;
      }
      case AndroidMessageTypes.PREFILL_TEMPLATE: {
        const customEvent = new CustomEvent('prefillTemplate', {
          detail: messageData,
        });
        document.dispatchEvent(customEvent);
        break;
      }
      case AndroidMessageTypes.SET_USER_STATUS: {
        const customEvent = new CustomEvent('setUserStatus', {
          detail: messageData,
        });
        document.dispatchEvent(customEvent);
        break;
      }
      default: {
        break;
      }
    }
  }

  isCurrentPlatform(): boolean {
    const currentPlatform =
      typeof window !== 'undefined' && typeof window.Android !== 'undefined';
    if (currentPlatform) {
      this.listenForMessageFromAndroid();
    }
    return currentPlatform;
  }

  hasMethod(functionName: string): boolean {
    return window.Android?.hasOwnProperty?.(functionName);
  }

  openCustomerChat(data: OpenCustomerChatData): void {}

  getUtmSource(): string {
    return 'android_app'
  }

  getToken(): Promise<string> {
    return new Promise((resolve) => {
      this.cachedPromise.login = {
        cachedPromiseResolve: resolve,
      };
      if (typeof window.Android?.requestSignInToken === 'function') {
        window.Android.requestSignInToken();
      }
    });
  }

  getVersion(): number {
    if (typeof window.Android?.getVersion === 'function') {
      return window.Android.getVersion();
    }
    return 0;
  }

  openNavigationDrawer(): void {
    if (typeof window.Android?.openNavigationDrawer === 'function') {
      window.Android.openNavigationDrawer();
    }
  }

  goBack(): void {
    if (typeof window.Android?.goBack === 'function') {
      window.Android.goBack();
    }
  }

  openRenewalBlocker(): void {
    // todo: use this to open renewal blocker natively
  }
  
  logout(): void {
    if (typeof window.Android?.logout === 'function') {
      window.Android.logout();
    }
  }

  openPricingPlans(): void {
    if (typeof window.Android?.openPricingPlans === 'function') {
      window.Android.openPricingPlans();
    }
  }

  selectTemplateFromList(): void {
    if (typeof window.Android?.selectTemplateFromList === 'function') {
      window.Android.selectTemplateFromList();
    }
  }

  finishActivity(): void {
    if (typeof window.Android?.finishActivity === 'function') {
      window.Android.finishActivity();
    }
  }

  openIntegrations(): void {
    if (typeof window.Android?.openIntegrations === 'function') {
      window.Android.openIntegrations();
    }
  }

  uploadCustomersFromPhonebook(): void {
    if (typeof window.Android?.uploadCustomersFromPhonebook === 'function') {
      window.Android.uploadCustomersFromPhonebook();
    }
  }

  setStatusBarColor(color: string = '#ffffff'): void {
    if (typeof window.Android?.setStatusBarColor === 'function') {
      window.Android.setStatusBarColor(color);
    }
  }

  setNavigationBarColor(color: string = '#ffffff'): void {
    if (typeof window.Android?.setNavigationBarColor === 'function') {
      window.Android.setNavigationBarColor(color);
    }
  }

  inviteContact(): void {
    if (typeof window.Android?.inviteContact === 'function') {
      window.Android.inviteContact();
    }
  }

  addGroupMembers(
    groupId: string,
    groupChatId: string,
    groupChatName: string
  ): void {
    if (typeof window.Android?.addGroupMembers === 'function') {
      window.Android.addGroupMembers(groupId, groupChatId, groupChatName);
    }
  }

  messageGroupMember({
    customerId,
    customerName,
    chatId,
    phoneNumber,
    integrationId,
    integrationWabaNumber,
  }: {
    customerId: string;
    customerName: string;
    chatId?: string;
    phoneNumber?: string;
    integrationId?: string;
    integrationWabaNumber?: string;
  }): void {
    if (typeof window.Android?.messageGroupMember === 'function') {
      if (this.getVersion() >= 6) {
        window.Android.messageGroupMember(
          customerId,
          customerName,
          chatId,
          phoneNumber,
          integrationId ?? null
        );
      } else {
        window.Android.messageGroupMember(customerId, customerName, chatId);
      }
    }
  }

  openGroupChat(
    groupChatId: string,
    groupId: string,
    groupChatName: string,
    groupAccessLevel: string,
    canManageGroupAccess: boolean
  ): void {
    if (typeof window.Android?.openGroupChat === 'function') {
      if (this.getVersion() >= 24) {
        return window.Android.openGroupChat(
          groupChatId,
          groupId,
          groupChatName,
          groupAccessLevel,
          canManageGroupAccess
        );
      }
      window.Android.openGroupChat(groupChatId, groupId, groupChatName);
    }
  }

  shareCommerce(data: ShareCommerce): void {
    if (typeof window.Android?.shareCommerce === 'function') {
      window.Android.shareCommerce(JSON.stringify(data));
    }
  }

  shareBroadcastCommerce(data: ShareBroadcastCommerce): void {
    if (typeof window.Android?.shareBroadcastCommerce === 'function') {
      window.Android.shareBroadcastCommerce(JSON.stringify(data));
    }
  }

  openCustomerDetailActivity(
    customerName: string,
    customerPhoneNumber: string
  ): void {
    if (typeof window.Android?.openCustomerDetailActivity === 'function') {
      window.Android.openCustomerDetailActivity(
        customerName,
        customerPhoneNumber
      );
    }
  }

  openDeepLink(url: string): void {
    if (typeof window.Android?.openDeepLink === 'function') {
      window.Android.openDeepLink(url);
    }
  }
  openLink(url: string): void {
    if (typeof window.Android?.openLink === 'function') {
      window.Android.openLink(url);
    }
  }

  trackEvent(eventName: AnalyticsEventNames, properties?: AnalyticsEventOptions): void {
    if (typeof window.Android?.trackEvent === 'function') {
      window.Android.trackEvent(eventName, properties);
    }
  }

  finishActivityWithError(message: string): void {
    if (typeof window.Android?.finishActivityWithError === 'function') {
      window.Android.finishActivityWithError(message);
    }
  }
  sendDataDebug(data: any): void {}

  redirect(url: string): void {}

  setRoutePath(path: Partial<NavigatePath>, options?: NavigateOptions): void {}

  copyToClipboard(data: string): Promise<any> {
    const input = document.createElement('textarea');
    input.innerHTML = data;
    document.body.appendChild(input);
    input.select();
    const result = document.execCommand('copy');
    document.body.removeChild(input);
    return result ? Promise.resolve('copied') : Promise.reject('failed');
  }

  shareData(data: string) {
    if (typeof window.Android?.shareData === 'function') {
      window.Android.shareData(data);
    }
  }

  finishActivityWithResultOk(): void {
    if (typeof window.Android?.finishActivityWithResultOk === 'function') {
      window.Android.finishActivityWithResultOk();
    }
  }

  createCustomField(customFieldId: string, type: CustomFieldsTypes): void {}

  updateCustomField(customFieldId: string, type: CustomFieldsTypes): void {}

  deleteCustomField(customFieldId: string, type: CustomFieldsTypes): void {}

  retargetTemplate(data: {
    action: string;
    chatId: string | null;
    groupId: string;
    broadcastId: string;
  }): void {
    if (typeof window.Android?.retargetTemplate === 'function') {
      const action = data.action.split('::')[0];
      const buttonText = data.action.split('::')[1];

      const { chatId, groupId, broadcastId } = data;

      window.Android.retargetTemplate(
        JSON.stringify({
          action,
          chatId,
          groupId,
          broadcastId,
          ...(buttonText ? { buttonText } : {}),
        })
      );
    }
  }

  getTemplateData(id: string): void {
    if (typeof window.Android?.getTemplateData === 'function') {
      window.Android.getTemplateData(id);
    }
  }

  sendCurlForTemplateAsApi(curl: string): void {
    if (typeof window.Android?.sendCurlForTemplateAsApi === 'function') {
      window.Android.sendCurlForTemplateAsApi(curl);
    }
  }

  onAddToBroadcastList(): void {}

  getSelectedCustomerIds(): void {}

  changeCustomToken(token: string): void {
    if (typeof window.Android?.changeCustomToken === 'function') {
      window.Android.changeCustomToken(token);
    }
  }

  async getCurrentPath(iframeId: string): Promise<CurrentPath> {
    return Promise.resolve({
      href: window.location.href,
      pathname: window.location.pathname,
      search: window.location.search,
      hash: window.location.hash,
      host: window.location.host,
      protocol: window.location.protocol,
    });
  }

  async dialog360Connect(): Promise<{
    client: string;
    channels: string[];
    revokedChannels?: string[];
  }> {
    return Promise.resolve({
      client: '',
      channels: [],
      revokedChannels: undefined,
    });
  }

  openBotBuilderModal(botId: string): void {}

  closeBotBuilderModal(): void {}

  openImportExcelModal(): void {}

  openAutoRetrySettingsModal(broadcastId: string): void {}

  setAutoRetry(broadcastId: string,state: FrequencyCappingState): void {}

  sendChannelMembersInfo(): void {}

  switchOrganisation(orgId: string): void {
    if (typeof window.Android?.switchOrganisation === 'function') {
      window.Android.switchOrganisation(orgId);
    }
  };

  setUserStatus(status: WabaIntegrationMemberStatus | null, statusExpiration: string | null): void {
    if (typeof window.Android?.setUserStatus === 'function') {
      window.Android.setUserStatus(status, statusExpiration);
    }
  }
}
